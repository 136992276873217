import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import * as System from "../../design-system"
import Image from "../components/image"
import StaticImage from "../components/static-image"
import { CURRENT_NUMBER_OF_THE_PRODUCTS } from "../shared/classConsts/hello-framer-x"
import { commaPipe } from "ruucm-util"

const Wrap = styled.div`
  padding: 0 20px;
  padding-top: 150px;
`

var PurchaseArea, PurchaseSection
var useStore, classOption, setClassOption
const Page = () => {
  if (typeof window !== "undefined") {
    PurchaseArea = require("../components/PurchaseArea").default
    PurchaseSection = require("../components/PurchaseArea/PurchaseSection")
      .default
    useStore = require("../datas/useStore").default
  }
  if (useStore) [classOption, setClassOption] = useStore()
  return (
    <Layout>
      <SEO
        title="하버스쿨 클래스 결제창"
        description=""
        metaImage={require("../images/hf3-1-min.png")}
      />
      <Wrap>
        {typeof window !== "undefined" && (
          <>
            <System.PageTitle
              h1={
                "Framer X 입문 웨비나 📡<br/><br/><strike>" +
                commaPipe(classOption.total) +
                "원</strike>"
                // "원 <strike>(5,000원)</strike>"
              }
            />
            <System.PageTitle
              h1={
                "마감 되었습니다"
                // "원 <strike>(5,000원)</strike>"
              }
            />
          </>
        )}
        {/* {typeof window !== "undefined" && (
          <div
            style={{
              width: "100vw",
              position: "absolute",
              left: 0,
              zIndex: 1,
              background: "white",
              height: 600,
            }}
          >
            <PurchaseSection manual={true} />
          </div>
        )} */}
      </Wrap>
    </Layout>
  )
}
export default Page
